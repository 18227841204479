const ServicesIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-name='Layer 1'
      width='825.80315'
      height='467'
      viewBox='0 0 825.80315 467'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <polygon
        points='155.06 453.964 142.8 453.964 140.968 406.676 155.062 406.677 155.06 453.964'
        style={{ fill: '#ffb8b8' }}
      />
      <path
        d='M345.28458,682.34851l-39.53052-.00146v-.5a15.38605,15.38605,0,0,1,15.38647-15.38623h.001l24.1438.001Z'
        style={{
          transform: 'translate(-187.09843px, -216.5px)',
          fill: '#2f2e41',
        }}
      />
      <polygon
        points='224.114 453.964 236.373 453.964 238.206 406.676 224.111 406.677 224.114 453.964'
        style={{ fill: '#ffb8b8' }}
      />
      <path
        d='M408.08477,666.4618l24.1438-.001h.001A15.38605,15.38605,0,0,1,447.616,681.84705v.5l-39.53052.00146Z'
        style={{
          transform: 'translate(-187.09843px, -216.5px)',
          fill: '#2f2e41',
        }}
      />
      <path
        d='M411.53392,664.40386a4.52007,4.52007,0,0,1-4.39405-3.52246L376.7534,528.34234a1.45736,1.45736,0,0,0-1.46411-1.17383h-.00757a1.45833,1.45833,0,0,0-1.46021,1.18847L345.917,660.00249a4.52164,4.52164,0,0,1-4.40234,3.56641H328.2219a4.50015,4.50015,0,0,1-4.49218-4.76563L333.6306,491.9478l3.98413-1.17871.0747.001,78.25806.68457L432.615,658.12652a4.49788,4.49788,0,0,1-4.11645,4.92187l-16.604,1.34082Q411.71324,664.40387,411.53392,664.40386Z'
        style={{
          transform: 'translate(-187.09843px, -216.5px)',
          fill: '#2f2e41',
        }}
      />
      <circle
        cx='192.11811'
        cy='96.97873'
        r='24.56103'
        style={{ fill: '#a0616a' }}
      />
      <path
        d='M364.17039,510.24175a42.39306,42.39306,0,0,1-12.72754-1.791,28.09705,28.09705,0,0,1-17.03808-14.375,4.50537,4.50537,0,0,1-.335-3.35449c2.70875-9.24511,15.79859-57.30078,7.44678-95.50293a40.48485,40.48485,0,0,1,5.75513-31.11426,39.97673,39.97673,0,0,1,25.927-17.43847l.00025-.001c1.38525-.27051,2.77807-.49219,4.13916-.66015a39.83565,39.83565,0,0,1,32.25781,10.61328,40.82033,40.82033,0,0,1,12.81567,32.36035L415.99266,491.982a4.46,4.46,0,0,1-2.39795,3.70313C406.20213,499.55913,383.9951,510.24175,364.17039,510.24175Z'
        style={{ transform: 'translate(-187.09843px, -216.5px)', fill: '#ccc' }}
      />
      <path
        d='M339.80433,493.32855a10.74272,10.74272,0,0,0,3.84155-16.01843l8.033-78.54005H334.31878l-6.384,76.69032a10.80091,10.80091,0,0,0,11.86954,17.86816Z'
        style={{
          transform: 'translate(-187.09843px, -216.5px)',
          fill: '#a0616a',
        }}
      />
      <path
        d='M352.90684,361.58805h0a14.22883,14.22883,0,0,1,11.391,14.94173l-2.39258,34.243a4,4,0,0,1-5.16111,3.546l-28.82426-8.82365a4,4,0,0,1-2.56243-5.261l11.46611-29.805A14.22883,14.22883,0,0,1,352.90684,361.58805Z'
        style={{ transform: 'translate(-187.09843px, -216.5px)', fill: '#ccc' }}
      />
      <path
        d='M406.963,295.39956a30.15571,30.15571,0,0,0-33.0527-18.58366l7.744,6.2578c-9.62157.324-19.74953-.36492-26.61937,6.37932,2.18707-.06067,5.05354,4.61732,7.24061,4.55664-4.08894.08088-7.79672,2.95388-9.60281,6.62321a18.86531,18.86531,0,0,0-1.114,11.99709c.8076,4.00921,6.96814,13.40165,8.62634,17.14011-.28516-9.77014,18.94922-33.0744,28.3418-30.64233a25.7722,25.7722,0,0,0-11.90045,9.03527,34.9042,34.9042,0,0,1,20.70091-3.38707,14.65,14.65,0,0,0,4.935.27623,6.02513,6.02513,0,0,0,3.57207-9.62851Z'
        style={{
          transform: 'translate(-187.09843px, -216.5px)',
          fill: '#2f2e41',
        }}
      />
      <path
        d='M569.09843,683.5h-381a1,1,0,0,1,0-2h381a1,1,0,0,1,0,2Z'
        style={{
          transform: 'translate(-187.09843px, -216.5px)',
          fill: '#3f3d56',
        }}
      />
      <rect
        x='812.80315'
        y='29'
        width='6'
        height='1'
        style={{ fill: '#e6e6e6' }}
      />
      <path
        d='M987.78048,246.5H975.65939v-1h12.12109Zm-24.24243,0H951.417v-1h12.12109Zm-24.24243,0h-12.1211v-1h12.1211Zm-24.24243,0h-12.1211v-1h12.1211Zm-24.24244,0H878.68966v-1h12.12109Zm-24.24243,0H854.44723v-1h12.12109Zm-24.24243,0H830.2048v-1h12.12109Zm-24.24243,0H805.96237v-1h12.12109Zm-24.24243,0h-12.1211v-1H793.841Zm-24.24243,0H757.4775v-1h12.1211Zm-24.24244,0H733.23507v-1h12.12109Zm-24.24243,0H708.99264v-1h12.12109Zm-24.24243,0H684.75021v-1H696.8713Zm-24.24243,0H660.50753v-1h12.12134Zm-24.24243,0h-12.1211v-1h12.1211Zm-24.24243,0H612.02267v-1H624.144Z'
        style={{
          transform: 'translate(-187.09843px, -216.5px)',
          fill: '#e6e6e6',
        }}
      />
      <rect
        x='406.80315'
        y='29'
        width='6'
        height='1'
        style={{ fill: '#e6e6e6' }}
      />
      <rect
        x='615.80315'
        y='122'
        width='6'
        height='1'
        style={{ fill: '#e6e6e6' }}
      />
      <path
        d='M790.96017,339.5H779.019v-1h11.94116Zm-23.88233,0H755.13668v-1h11.94116Zm-23.88232,0H731.25436v-1h11.94116Zm-23.88232,0H707.372v-1H719.3132Zm-23.88233,0h-11.9414v-1h11.9414Zm-23.88257,0H659.60714v-1H671.5483Zm-23.88232,0H635.72482v-1H647.666Zm-23.88233,0H611.84249v-1h11.94116Z'
        style={{
          transform: 'translate(-187.09843px, -216.5px)',
          fill: '#e6e6e6',
        }}
      />
      <rect
        x='406.80315'
        y='122'
        width='6'
        height='1'
        style={{ fill: '#e6e6e6' }}
      />
      <rect
        x='498.80315'
        y='166'
        width='6'
        height='1'
        style={{ fill: '#e6e6e6' }}
      />
      <path
        d='M673.61593,383.5H661.33029v-1h12.28564Zm-24.57153,0H636.75875v-1H649.0444Zm-24.57129,0H612.18746v-1h12.28565Z'
        style={{
          transform: 'translate(-187.09843px, -216.5px)',
          fill: '#e6e6e6',
        }}
      />
      <rect
        x='406.80315'
        y='166'
        width='6'
        height='1'
        style={{ fill: '#e6e6e6' }}
      />
      <path
        d='M1006.0715,256.51V427.77a32.53759,32.53759,0,0,1-32.5,32.5h-348a32.3954,32.3954,0,0,1-24.16993-10.8v-1.53a31.40764,31.40764,0,0,0,23.97022,11.33l60.18994-60.19a8.49808,8.49808,0,0,1,12.02,0l31.98975,31.98,74.99023-74.98a8.49808,8.49808,0,0,1,12.02,0l43.98975,43.98,143.51025-143.5.98975-.03.46-.01Z'
        style={{
          transform: 'translate(-187.09843px, -216.5px)',
          fill: '#61dafb',
        }}
      />
      <path
        d='M973.90157,460.5h-348a32.53685,32.53685,0,0,1-32.5-32.5V249a32.53685,32.53685,0,0,1,32.5-32.5h348a32.53684,32.53684,0,0,1,32.5,32.5V428A32.53684,32.53684,0,0,1,973.90157,460.5Zm-348-243a31.5357,31.5357,0,0,0-31.5,31.5V428a31.5357,31.5357,0,0,0,31.5,31.5h348a31.53569,31.53569,0,0,0,31.5-31.5V249a31.53569,31.53569,0,0,0-31.5-31.5Z'
        style={{
          transform: 'translate(-187.09843px, -216.5px)',
          fill: '#3f3d56',
        }}
      />
      <path
        d='M626.25509,460.35352l-.707-.707,60.34326-60.34326a8.50871,8.50871,0,0,1,12.02051,0L729.90157,431.293l74.98975-74.98975a8.50871,8.50871,0,0,1,12.02051,0L860.90157,400.293l144.64649-144.64649.707.707L860.90157,401.707,816.2048,357.01025a7.49965,7.49965,0,0,0-10.60645,0L729.90157,432.707,697.2048,400.01025a7.50909,7.50909,0,0,0-10.60645,0Z'
        style={{
          transform: 'translate(-187.09843px, -216.5px)',
          fill: '#3f3d56',
        }}
      />
      <circle cx='504.80315' cy='166.5' r='7' style={{ fill: '#3f3d56' }} />
      <circle cx='621.80315' cy='122.5' r='7' style={{ fill: '#3f3d56' }} />
      <circle cx='818.80315' cy='29.5' r='7' style={{ fill: '#3f3d56' }} />
      <ellipse
        cx='171.4688'
        cy='254.69423'
        rx='26.28967'
        ry='37.41601'
        style={{ fill: '#3f3d56' }}
      />
      <ellipse
        cx='171.4688'
        cy='254.69423'
        rx='26.28967'
        ry='37.41601'
        style={{ opacity: '0.1', isolation: 'isolate' }}
      />
      <ellipse
        cx='175.01348'
        cy='254.69423'
        rx='26.28967'
        ry='37.41601'
        style={{ fill: '#3f3d56' }}
      />
      <ellipse
        cx='175.01348'
        cy='254.69422'
        rx='22.54807'
        ry='32.09088'
        style={{ opacity: '0.1', isolation: 'isolate' }}
      />
      <path
        d='M361.91656,486.65213v-3.767a8.74716,8.74716,0,0,1-5.0232-1.64113l.78787-2.49886a8.02982,8.02982,0,0,0,4.72777,1.60363,3.621,3.621,0,0,0,3.907-3.655c0-2.05143-1.28048-3.31943-3.71-4.43846-3.34886-1.4918-5.41723-3.2076-5.41723-6.45237a5.86421,5.86421,0,0,1,4.95765-6.00493v-3.767h2.03552v3.61789a7.988,7.988,0,0,1,4.26817,1.30534l-.8209,2.4617a7.255,7.255,0,0,0-4.13675-1.26817,3.152,3.152,0,0,0-3.48016,3.2076c0,1.93943,1.21478,2.90913,4.07118,4.252,3.38157,1.56647,5.08893,3.5059,5.08893,6.82531a6.24154,6.24154,0,0,1-5.18752,6.34037v3.87922Z'
        style={{
          transform: 'translate(-187.09843px, -216.5px)',
          fill: '#61dafb',
        }}
      />
      <path
        d='M376.18518,449.75941a10.52626,10.52626,0,0,1,1.62647.3192l37.64605-32.28412-2.20057-11.84534,17.44265-5.65756,5.93357,20.59125a8,8,0,0,1-3.40936,8.97532l-47.67745,30.1704a10.4971,10.4971,0,1,1-9.36136-10.26915Z'
        style={{
          transform: 'translate(-187.09843px, -216.5px)',
          fill: '#a0616a',
        }}
      />
      <path
        d='M408.44,362.82113l0,0a14.22885,14.22885,0,0,1,16.34482,9.26593l11.25127,32.43013a4,4,0,0,1-3.35327,5.28837l-29.97332,3.20857a4,4,0,0,1-4.4231-3.83151l-1.16377-31.9132A14.22883,14.22883,0,0,1,408.44,362.82113Z'
        style={{ transform: 'translate(-187.09843px, -216.5px)', fill: '#ccc' }}
      />
    </svg>
  );
};

export default ServicesIcon;
