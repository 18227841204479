import React from 'react';

const WorldIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-name='Layer 1'
      width='807.85972'
      height='581.58175'
      viewBox='0 0 807.85972 581.58175'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <path
        d='M901.32664,359.28559c-29.23789-21.85782-75.03171-42.52949-125.63917-56.71393-46.07822-12.91529-91.02143-19.15081-126.56032-17.55845l-.185-4.13293c35.96224-1.61055,81.37165,4.67732,127.862,17.70792,51.07935,14.31675,97.36929,35.23244,126.99984,57.384Z'
        style={{
          transform: 'translate(-196.07014px, -159.20912px)',
          fill: '#ccc',
        }}
      />
      <path
        d='M898.29142,376.12168a137.67178,137.67178,0,0,1-11.91446,56.2089q-.98672,2.22156-2.0561,4.40172a138.19449,138.19449,0,0,1-262.37841-60.6106q0-4.79685.3268-9.51915.15517-2.4015.40132-4.774a138.18139,138.18139,0,0,1,275.62085,14.29316Z'
        style={{
          transform: 'translate(-196.07014px, -159.20912px)',
          fill: '#61dafb',
        }}
      />
      <path
        d='M886.377,432.33058q-.98672,2.22156-2.0561,4.40172-6.05652.6578-12.75013.95563c-3.69431.16548-7.49618.24823-11.38077.24823-33.93136,0-74.7673-6.26336-116.48441-17.95442-46.49117-13.03142-88.55164-31.25886-118.43706-51.32724q-1.52654-1.02391-2.99928-2.05195.15516-2.4015.40132-4.774,2.37669,1.69405,4.90228,3.39228c29.53375,19.82846,71.17228,37.86147,117.24976,50.77708,41.353,11.59174,81.80426,17.80133,115.34672,17.80133q5.75241,0,11.21531-.2441Q879.34006,433.1952,886.377,432.33058Z'
        style={{
          transform: 'translate(-196.07014px, -159.20912px)',
          opacity: '0.1',
          isolation: 'isolate',
        }}
      />
      <path
        d='M860.19056,434.62735c-33.93033.00081-74.76867-6.26321-116.48394-17.95538-46.4907-13.03061-88.553-31.25872-118.43869-51.32658-30.50945-20.48664-44.81593-40.67331-40.28428-56.84141,4.53149-16.16808,27.24578-25.97842,63.95844-27.6237l.185,4.13293c-17.14436.7682-31.11629,3.31645-41.52756,7.57379-10.386,4.24685-16.65492,9.97779-18.63248,17.03366-1.97757,7.05566.39956,15.20979,7.06516,24.23616,6.68194,9.04818,17.29424,18.48685,31.54191,28.054,29.53037,19.82933,71.17027,37.86269,117.24916,50.77777,41.35405,11.59075,81.80173,17.80186,115.34706,17.80265q5.74914,0,11.213-.24442c17.14453-.76841,31.11646-3.31645,41.528-7.57379,10.386-4.24685,16.65492-9.97758,18.63248-17.03345,3.55077-12.66943-7.18028-29.13164-30.21719-46.354l2.47736-3.31319c11.85458,8.86254,20.62986,17.59844,26.08223,25.96449,5.84508,8.96919,7.743,17.31987,5.64106,24.81932-4.53166,16.16808-27.24616,25.978-63.95844,27.62351C867.87462,434.54535,864.075,434.62735,860.19056,434.62735Z'
        style={{
          transform: 'translate(-196.07014px, -159.20912px)',
          fill: '#ccc',
        }}
      />
      <circle
        cx='567.70201'
        cy='52.23483'
        r='1.58335'
        style={{ fill: '#f2f2f2' }}
      />
      <circle
        cx='357.64502'
        cy='119.80078'
        r='2.7239'
        style={{ fill: '#ccc' }}
      />
      <circle
        cx='351.83942'
        cy='226.41262'
        r='5.38101'
        style={{ fill: '#ccc' }}
      />
      <circle
        cx='674.84163'
        cy='61.73489'
        r='3.58631'
        style={{ fill: '#ccc' }}
      />
      <circle
        cx='802.03694'
        cy='210.57918'
        r='5.82278'
        style={{ fill: '#ccc' }}
      />
      <circle
        cx='756.50568'
        cy='117.57999'
        r='2.49824'
        style={{ fill: '#ccc' }}
      />
      <circle
        cx='453.17346'
        cy='85.48506'
        r='3.89733'
        style={{ fill: '#ccc' }}
      />
      <circle
        cx='747.76185'
        cy='198.77239'
        r='2.49824'
        style={{ fill: '#ccc' }}
      />
      <circle
        cx='364.50618'
        cy='302.41316'
        r='3.10939'
        style={{ fill: '#ccc' }}
      />
      <circle
        cx='427.31216'
        cy='345.69122'
        r='3.36729'
        style={{ fill: '#ccc' }}
      />
      <polygon
        points='525.2 6.645 518.555 6.645 518.555 0 514.99 0 514.99 6.645 508.342 6.645 508.342 10.213 514.99 10.213 514.99 16.858 518.555 16.858 518.555 10.213 525.2 10.213 525.2 6.645'
        style={{ fill: '#ccc' }}
      />
      <polygon
        points='679.342 366.201 675.168 366.201 675.168 362.029 672.93 362.029 672.93 366.201 668.758 366.201 668.758 368.44 672.93 368.44 672.93 372.612 675.168 372.612 675.168 368.44 679.342 368.44 679.342 366.201'
        style={{ fill: '#ccc' }}
      />
      <polygon
        points='775.646 344.844 772.527 344.844 772.527 341.725 770.852 341.725 770.852 344.844 767.733 344.844 767.733 346.519 770.852 346.519 770.852 349.638 772.527 349.638 772.527 346.519 775.646 346.519 775.646 344.844'
        style={{ fill: '#ccc' }}
      />
      <circle
        cx='566.1187'
        cy='108.71737'
        r='11.61119'
        style={{ opacity: '0.1', isolation: 'isolate' }}
      />
      <circle
        cx='476.92363'
        cy='193.69017'
        r='11.61119'
        style={{ opacity: '0.1', isolation: 'isolate' }}
      />
      <circle
        cx='605.17451'
        cy='310.32987'
        r='11.61119'
        style={{ opacity: '0.1', isolation: 'isolate' }}
      />
      <circle
        cx='566.1187'
        cy='211.63475'
        r='11.61119'
        style={{ opacity: '0.1', isolation: 'isolate' }}
      />
      <circle
        cx='482.72921'
        cy='275.49629'
        r='11.61119'
        style={{ opacity: '0.1', isolation: 'isolate' }}
      />
      <circle
        cx='628.3969'
        cy='149.35654'
        r='11.61119'
        style={{ opacity: '0.1', isolation: 'isolate' }}
      />
      <circle
        cx='666.92492'
        cy='227.99597'
        r='11.61119'
        style={{ opacity: '0.1', isolation: 'isolate' }}
      />
      <circle
        cx='566.1187'
        cy='160.96774'
        r='15.30567'
        style={{ fill: '#fff' }}
      />
      <path
        d='M762.18884,298.01a21.639,21.639,0,0,0-21.639,21.639c0,11.9509,21.639,51.19479,21.639,51.19479s21.639-39.24389,21.639-51.19479A21.639,21.639,0,0,0,762.18884,298.01Zm0,32.19467a10.02787,10.02787,0,1,1,10.02789-10.02787,10.02785,10.02785,0,0,1-10.02789,10.02787h0Z'
        style={{
          transform: 'translate(-196.07014px, -159.20912px)',
          fill: '#ccc',
        }}
      />
      <circle
        cx='482.72921'
        cy='226.41262'
        r='15.30565'
        style={{ fill: '#fff' }}
      />
      <path
        d='M678.79935,363.45492a21.639,21.639,0,0,0-21.639,21.639c0,11.9509,21.639,51.19478,21.639,51.19478s21.639-39.24388,21.639-51.19478A21.639,21.639,0,0,0,678.79935,363.45492Zm0,32.19466a10.02787,10.02787,0,1,1,10.02789-10.02786,10.02784,10.02784,0,0,1-10.02789,10.02786h0Z'
        style={{
          transform: 'translate(-196.07014px, -159.20912px)',
          fill: '#ccc',
        }}
      />
      <circle
        cx='666.92492'
        cy='178.9123'
        r='15.30567'
        style={{ fill: '#fff' }}
      />
      <path
        d='M862.9951,315.9546a21.639,21.639,0,0,0-21.639,21.63906c0,11.9509,21.639,51.19478,21.639,51.19478s21.639-39.24388,21.639-51.19478A21.639,21.639,0,0,0,862.9951,315.9546Zm0,32.19466A10.02787,10.02787,0,1,1,873.023,338.1214a10.02784,10.02784,0,0,1-10.02789,10.02786Z'
        style={{
          transform: 'translate(-196.07014px, -159.20912px)',
          fill: '#ccc',
        }}
      />
      <circle
        cx='566.1187'
        cy='58.05035'
        r='15.30567'
        style={{ fill: '#fff' }}
      />
      <path
        d='M762.18884,195.09266a21.63905,21.63905,0,0,0-21.639,21.639c0,11.95091,21.639,51.1948,21.639,51.1948s21.639-39.24389,21.639-51.1948A21.639,21.639,0,0,0,762.18884,195.09266Zm0,32.19467a10.02787,10.02787,0,1,1,10.02789-10.02787,10.02785,10.02785,0,0,1-10.02789,10.02787Z'
        style={{
          transform: 'translate(-196.07014px, -159.20912px)',
          fill: '#ccc',
        }}
      />
      <circle
        cx='605.17451'
        cy='261.2462'
        r='15.30567'
        style={{ fill: '#fff' }}
      />
      <path
        d='M801.24465,398.2885a21.639,21.639,0,0,0-21.639,21.639c0,11.9509,21.639,51.19478,21.639,51.19478s21.639-39.24388,21.639-51.19478A21.639,21.639,0,0,0,801.24465,398.2885Zm0,32.19467a10.02787,10.02787,0,1,1,10.02789-10.02787,10.02785,10.02785,0,0,1-10.02789,10.02787Z'
        style={{
          transform: 'translate(-196.07014px, -159.20912px)',
          fill: '#3f3d56',
        }}
      />
      <circle
        cx='476.92363'
        cy='143.55094'
        r='15.30567'
        style={{ fill: '#fff' }}
      />
      <path
        d='M672.99378,280.59326a21.639,21.639,0,0,0-21.639,21.639c0,11.9509,21.639,51.19479,21.639,51.19479s21.639-39.24389,21.639-51.19479A21.639,21.639,0,0,0,672.99378,280.59326Zm0,32.19466a10.02787,10.02787,0,1,1,10.02788-10.02787,10.02786,10.02786,0,0,1-10.02788,10.02787Z'
        style={{
          transform: 'translate(-196.07014px, -159.20912px)',
          fill: '#3f3d56',
        }}
      />
      <circle
        cx='628.3969'
        cy='100.80065'
        r='15.30567'
        style={{ fill: '#fff' }}
      />
      <path
        d='M824.467,237.843A21.639,21.639,0,0,0,802.828,259.482c0,11.95091,21.639,51.19479,21.639,51.19479s21.639-39.24389,21.639-51.1948A21.639,21.639,0,0,0,824.467,237.843Zm0,32.19467a10.02787,10.02787,0,1,1,10.02788-10.02787A10.02786,10.02786,0,0,1,824.467,270.03762h0Z'
        style={{
          transform: 'translate(-196.07014px, -159.20912px)',
          fill: '#3f3d56',
        }}
      />
      <polygon
        points='188.862 569.715 201.044 568.332 201.509 520.688 183.53 522.729 188.862 569.715'
        style={{ fill: '#ffb8b8' }}
      />
      <path
        d='M382.64322,723.51987h38.53073a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H397.53007a14.88686,14.88686,0,0,1-14.88686-14.88686v0A0,0,0,0,1,382.64322,723.51987Z'
        style={{
          transform: 'translate(687.60258px, 1252.73963px) rotate(173.5254deg)',
          fill: '#2f2e41',
        }}
      />
      <polygon
        points='63.582 568.954 75.842 568.953 81.674 521.665 63.58 521.666 63.582 568.954'
        style={{ fill: '#ffb8b8' }}
      />
      <path
        d='M257.02549,724.65948h38.53073a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H271.91235a14.88686,14.88686,0,0,1-14.88686-14.88686v0A0,0,0,0,1,257.02549,724.65948Z'
        style={{
          transform: 'translate(356.54499px, 1304.98409px) rotate(179.9978deg)',
          fill: '#2f2e41',
        }}
      />
      <path
        d='M347.61369,516.69512l7,22.76923,38,82.23077,10,78-23,1-14-72-48-52-42,136-19-2s16-176,38-194C294.61369,516.69512,330.61369,499.69512,347.61369,516.69512Z'
        style={{
          transform: 'translate(-196.07014px, -159.20912px)',
          fill: '#2f2e41',
        }}
      />
      <circle
        cx='120.49237'
        cy='234.6789'
        r='24.56103'
        style={{ fill: '#ffb8b8' }}
      />
      <path
        d='M350.11369,521.19512s3-102-39-95-24.5,105.5-24.5,105.5,9,18,31-2S350.11369,521.19512,350.11369,521.19512Z'
        style={{
          transform: 'translate(-196.07014px, -159.20912px)',
          fill: '#ccc',
        }}
      />
      <polygon
        points='96.044 309.986 105.927 347.869 120.984 362.927 100.044 355.986 96.044 309.986'
        opacity='0.2'
      />
      <path
        d='M350.424,564.33339a10.05576,10.05576,0,0,1-5.277-14.48823l-23.35887-27.04335,18.41436-2.39689,19.35886,26.04829a10.11028,10.11028,0,0,1-9.13733,17.88018Z'
        style={{
          transform: 'translate(-196.07014px, -159.20912px)',
          fill: '#ffb8b8',
        }}
      />
      <path
        d='M321.98365,446.97089l2.63,51.72423,31,40-13,10-42-42s-15-51-4-66c4.125-5.625,8.67188-7.17188,12.74414-6.90821A13.78031,13.78031,0,0,1,321.98365,446.97089Z'
        style={{
          transform: 'translate(-196.07014px, -159.20912px)',
          fill: '#ccc',
        }}
      />
      <path
        d='M313.64139,419.69532a1.01358,1.01358,0,0,0-.14318-1.61666,14.28328,14.28328,0,0,1,1.37609-25.03743c7.22106-3.44291,17.00605-.76444,22.34414-7.38039a12.40873,12.40873,0,0,0,2.30264-10.0772c-1.15182-6.24874-5.8494-10.8893-11.11025-14.06959a40.429,40.429,0,0,0-61.08911,39.13442c.67906,6.01412,2.53479,12.75778-1.04129,17.64065-3.14734,4.29745-9.08815,5.10326-14.30673,6.17108-11.19313,2.29031-21.99165,7.57389-29.58753,16.1082s-11.64806,20.50823-9.35788,31.70139,11.47806,21.03009,22.77061,22.76506c9.19165,1.41219,18.68267-2.4913,25.43252-8.88821s11.01639-15.03534,13.70753-23.93695c3.19415-10.56543,4.4541-21.95037,10.22083-31.36184,5.64312-9.20972,17.80394-15.81164,27.33492-11.01525a1.022,1.022,0,0,0,1.14669-.13728Z'
        style={{
          transform: 'translate(-196.07014px, -159.20912px)',
          fill: '#2f2e41',
        }}
      />
      <path
        d='M504.07014,740.79088h-307a1,1,0,0,1,0-2h307a1,1,0,1,1,0,2Z'
        style={{
          transform: 'translate(-196.07014px, -159.20912px)',
          fill: '#3f3d56',
        }}
      />
    </svg>
  );
};

export default WorldIcon;
